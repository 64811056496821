import React, { useState } from "react"
import Accordion from "react-bootstrap/Accordion"
import Container from "react-bootstrap/Container"
import Card from "react-bootstrap/Card"
import "./ConsultingTechStacksMobile.scss"
import * as styles from "./ConsultingTechStacksMobile.module.scss"

const ConsultingTechStacksMobile = ({ strapiData }) => {
  const data = [
    {
      title: "Programming Languages",
      tech: [
        {
          name: "Ruby",
          icon: "https://invozone-backend.s3.amazonaws.com/ruby_39cbc78603.svg",
        },
        {
          name: "Python",
          icon: "https://invozone-backend.s3.amazonaws.com/python_6152abcd7d.svg",
        },
        {
          name: "Java",
          icon: "https://invozone-backend.s3.amazonaws.com/java_71543afe19.svg",
        },
        {
          name: "PHP",
          icon: "https://invozone-backend.s3.amazonaws.com/php_e2138e8e1c.svg",
        },
        {
          name: "Go",
          icon: "https://invozone-backend.s3.amazonaws.com/golang_b11710166e.svg",
        },
        {
          name: "Kotlin",
          icon: "https://invozone-backend.s3.amazonaws.com/kotlin_54ea407d98.svg",
        },
        {
          name: "JavaScript",
          icon: "https://invozone-backend.s3.amazonaws.com/javascript_1d5a458fb2.svg",
        },
        {
          name: "C#",
          icon: "https://invozone-backend.s3.amazonaws.com/C_01bd0dbef1.svg",
        },
        {
          name: "Elixir",
          icon: "https://invozone-backend.s3.amazonaws.com/elixir_1ac031e4cc.svg",
        },
        {
          name: "Rust",
          icon: "https://invozone-backend.s3.amazonaws.com/rust_86ad4c60c8.svg",
        },
        {
          name: "Objective-C",
          icon: "https://invozone-backend.s3.amazonaws.com/objective_c_ef4582f8a8.svg",
        },
        {
          name: "Swift",
          icon: "https://invozone-backend.s3.amazonaws.com/swift_56be54949b.svg",
        },
        {
          name: "TypeScript",
          icon: "https://invozone-backend.s3.amazonaws.com/typescript_6154a911e5.svg",
        },
        {
          name: "GraphQL",
          icon: "https://invozone-backend.s3.amazonaws.com/graphql_64900f0cb9.svg",
        },
        {
          name: "C++",
          icon: "https://invozone-backend.s3.amazonaws.com/c_bfec59a50e.svg",
        },
      ],
    },
    {
      title: "Frameworks",
      tech: [
        {
          name: "Ionic",
          icon: "https://invozone-backend.s3.amazonaws.com/ionic_8a4a0f560f.svg",
        },
        {
          name: "Bootstrap",
          icon: "https://invozone-backend.s3.amazonaws.com/bootstrap_b1945a76d4.svg",
        },
        {
          name: "NestJs",
          icon: "https://invozone-backend.s3.amazonaws.com/nest_js_0bafededef.svg",
        },
        {
          name: "Scully",
          icon: "https://invozone-backend.s3.amazonaws.com/Scully_0a31198239.svg",
        },
        {
          name: "Universal",
          icon: "https://invozone-backend.s3.amazonaws.com/universal_6a2a232212.svg",
        },
        {
          name: "AngularFire",
          icon: "https://invozone-backend.s3.amazonaws.com/angular_fire_f88a87bd4f.svg",
        },
        {
          name: "Capacitor",
          icon: "https://invozone-backend.s3.amazonaws.com/capacitor_a3a296803d.svg",
        },
        {
          name: "Flutter",
          icon: "https://invozone-backend.s3.amazonaws.com/flutter_c088c7bb43.svg",
        },
        {
          name: "Vue.js",
          icon: "https://invozone-backend.s3.amazonaws.com/vue_js_57c953c617.svg",
        },
        {
          name: "Tailwind",
          icon: "https://invozone-backend.s3.amazonaws.com/tailwind_6e2075c057.svg",
        },
        {
          name: "AngularJS",
          icon: "https://invozone-backend.s3.amazonaws.com/angular_3e5d058b96.svg",
        },
        {
          name: "Angular2+",
          icon: "https://invozone-backend.s3.amazonaws.com/angular_2_a8fee409a1.svg",
        },
        {
          name: "Django",
          icon: "https://invozone-backend.s3.amazonaws.com/django_bc7d32b851.svg",
        },
        {
          name: "Flask",
          icon: "https://invozone-backend.s3.amazonaws.com/flask_ad187fbd86.svg",
        },
        {
          name: "FastAPI",
          icon: "https://invozone-backend.s3.amazonaws.com/fastapi_c54992c5d8.svg",
        },
        {
          name: "Ruby on Rails",
          icon: "https://invozone-backend.s3.amazonaws.com/ruby_on_rails_efefcbd3e8.svg",
        },
        {
          name: "Sprint Boot",
          icon: "https://invozone-backend.s3.amazonaws.com/spring_boot_25e5d95b39.svg",
        },
        {
          name: "ASP.NET",
          icon: "https://invozone-backend.s3.amazonaws.com/asp_dot_net_1ecce0ad8d.svg",
        },
        {
          name: "Phoenix",
          icon: "https://invozone-backend.s3.amazonaws.com/phoenix_d70f77ea9c.svg",
        },
        {
          name: "Gin",
          icon: "https://invozone-backend.s3.amazonaws.com/gin_3c4dbc3ea5.svg",
        },
        {
          name: "Laravel",
          icon: "https://invozone-backend.s3.amazonaws.com/laravel_9de66f7718.svg",
        },
        {
          name: "Rocket",
          icon: "https://invozone-backend.s3.amazonaws.com/rocket_4b926b166b.svg",
        },
        {
          name: "NodeJs",
          icon: "https://invozone-backend.s3.amazonaws.com/nodejs_f1ad2a2c65.svg",
        },
        {
          name: "Express",
          icon: "https://invozone-backend.s3.amazonaws.com/tech6_3f5ab1979a.svg",
        },
        {
          name: "Ktor",
          icon: "https://invozone-backend.s3.amazonaws.com/ktor_7e172d42d7.svg",
        },
      ],
    },
    {
      title: "Databases",
      tech: [
        {
          name: "SQL server",
          icon: "https://invozone-backend.s3.amazonaws.com/microsoft_sql_server_7650db5e65.svg",
        },
        {
          name: "MySQL",
          icon: "https://invozone-backend.s3.amazonaws.com/mysql_b1008b93a5.svg",
        },
        {
          name: "Azure SQL",
          icon: "https://invozone-backend.s3.amazonaws.com/azure_sql_5def8e363f.svg",
        },
        {
          name: "Oracle",
          icon: "https://invozone-backend.s3.amazonaws.com/oracle_adb2506abc.svg",
        },
        {
          name: "Elasticsearch",
          icon: "https://invozone-backend.s3.amazonaws.com/elasticsearch_d486dc437d.svg",
        },
        {
          name: "PostgreSQL",
          icon: "https://invozone-backend.s3.amazonaws.com/postgresql_b9e4bb71b7.svg",
        },
        {
          name: "MongoDB",
          icon: "https://invozone-backend.s3.amazonaws.com/mongodb_7362fb906b.svg",
        },
        {
          name: "AmazonRDS",
          icon: "https://invozone-backend.s3.amazonaws.com/aws_rds_db5b00e503.svg",
        },
        {
          name: "Dynamo",
          icon: "https://invozone-backend.s3.amazonaws.com/dynamo_1dda785c0a.svg",
        },
        {
          name: "Redis",
          icon: "https://invozone-backend.s3.amazonaws.com/redis_02864b6029.svg",
        },
        {
          name: "MariaDB",
          icon: "https://invozone-backend.s3.amazonaws.com/mariadb_478f735ed2.svg",
        },
        {
          name: "SQLite",
          icon: "https://invozone-backend.s3.amazonaws.com/sqlite_ac79b80b2e.svg",
        },
      ],
    },
    {
      title: "Testing",
      tech: [
        {
          name: "Postman",
          icon: "https://invozone-backend.s3.amazonaws.com/postman_531dd20323.svg",
        },
        {
          name: "Jmeter",
          icon: "https://invozone-backend.s3.amazonaws.com/jmeter_2b862ac481.svg",
        },
        {
          name: "Selenium",
          icon: "https://invozone-backend.s3.amazonaws.com/selenium_ecde5053d2.svg",
        },
        {
          name: "Cypress",
          icon: "https://invozone-backend.s3.amazonaws.com/cypress_216f4b3135.svg",
        },
        {
          name: "BrowserStack",
          icon: "https://invozone-backend.s3.amazonaws.com/browserstack_858d0150a1.svg",
        },
        {
          name: "Bugzilla",
          icon: "https://invozone-backend.s3.amazonaws.com/bugzilla_2760fd8704.svg",
        },
        {
          name: "SoapUI",
          icon: "https://invozone-backend.s3.amazonaws.com/soapui_fde55b50e3.svg",
        },
        {
          name: "Jasmine",
          icon: "https://invozone-backend.s3.amazonaws.com/jasmine_b9be38fa6b.svg",
        },
        {
          name: "Karma",
          icon: "https://invozone-backend.s3.amazonaws.com/karma_c5e86ed042.svg",
        },
        {
          name: "Protractor",
          icon: "https://invozone-backend.s3.amazonaws.com/protractor_df67c1a67a.svg",
        },
      ],
    },
    {
      title: "Cloud & DevOps",
      tech: [
        {
          name: "AWS",
          icon: "https://invozone-backend.s3.amazonaws.com/aws_a26f4f0199.svg",
        },
        {
          name: "Google Cloud",
          icon: "https://invozone-backend.s3.amazonaws.com/google_cloud_107301e12a.svg",
        },
        {
          name: "Kubernetes",
          icon: "https://invozone-backend.s3.amazonaws.com/kubernetes_d5b3b12c3e.svg",
        },
        {
          name: "Docker",
          icon: "https://invozone-backend.s3.amazonaws.com/docker_0c21af2970.svg",
        },
        {
          name: "Jenkins",
          icon: "https://invozone-backend.s3.amazonaws.com/jenkins_c4e3561565.svg",
        },
        {
          name: "Azure",
          icon: "https://invozone-backend.s3.amazonaws.com/azure_sql_5def8e363f.svg",
        },
      ],
    },
  ]

  const [activeAccordion, setActiveAccordion] = useState(`${data[0]?.title}`)
  return (
    <div className="techWebConsultingMobile">
      <div className={styles.TechConsultingMobile}>
        <Container>
          <h2
            className={styles.heading}
            dangerouslySetInnerHTML={{ __html: strapiData?.title }}
          ></h2>
          <div
            className={styles.description}
            dangerouslySetInnerHTML={{
              __html: strapiData?.subTitle,
            }}
          />
          <Accordion defaultActiveKey={`${data[0]?.title}`}>
            {data &&
              data?.map((e, i) => (
                <Card className="consultCard" key={i}>
                  <Accordion.Toggle
                    as={Card.Header}
                    eventKey={`${e?.title}`}
                    className={`ConsultingAccord ${
                      e?.title === activeAccordion ? "active-accordion" : ""
                    } ${
                      (i === 1 || i === 3 || i === 5) &&
                      e?.title !== activeAccordion
                        ? "second-accordion"
                        : (i === 0 || i === 2 || i === 4) &&
                          e?.title !== activeAccordion
                        ? "first-accordion"
                        : ""
                    }`}
                    onClick={() => setActiveAccordion(e?.title)}
                    style={
                      i === 1 && e?.title === activeAccordion
                        ? { marginTop: "24px" }
                        : {}
                    }
                  >
                    {e?.title}
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey={`${e?.title}`}>
                    <Card.Body className={`cardBody`}>
                      <div className={styles.techIcon}>
                        {e?.tech &&
                          e?.tech?.map((el, i) => (
                            <div className={`${styles.iconssCard}`} key={i}>
                              <div className={styles.technologyIcon}>
                                <div className={styles.techImg}>
                                  <img
                                    decoding="async"
                                    loading="lazy"
                                    src={el?.icon}
                                    alt={el?.name}
                                  />
                                </div>
                                <h3>{el?.name}</h3>
                              </div>
                            </div>
                          ))}
                      </div>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              ))}
          </Accordion>
        </Container>
      </div>
    </div>
  )
}

export default ConsultingTechStacksMobile
