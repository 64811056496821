// extracted by mini-css-extract-plugin
export var TechConsultingMobile = "ConsultingTechStacksMobile-module--TechConsultingMobile--5fbb8";
export var cardBg = "ConsultingTechStacksMobile-module--cardBg--296ab";
export var cards = "ConsultingTechStacksMobile-module--cards--ed6bd";
export var cardsContent = "ConsultingTechStacksMobile-module--cardsContent--f5319";
export var description = "ConsultingTechStacksMobile-module--description--c152a";
export var heading = "ConsultingTechStacksMobile-module--heading--aabf3";
export var iconssCard = "ConsultingTechStacksMobile-module--iconssCard--516f8";
export var nav = "ConsultingTechStacksMobile-module--nav--81b5f";
export var navItem = "ConsultingTechStacksMobile-module--nav-item--464b0";
export var navbarBlock = "ConsultingTechStacksMobile-module--navbarBlock--9a6b0";
export var tabData = "ConsultingTechStacksMobile-module--tabData--15d61";
export var tech = "ConsultingTechStacksMobile-module--tech--d0c93";
export var techIcon = "ConsultingTechStacksMobile-module--techIcon--d713f";
export var techImg = "ConsultingTechStacksMobile-module--techImg--9c164";
export var technologyIcon = "ConsultingTechStacksMobile-module--technologyIcon--6caac";