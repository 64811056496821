import { Script, graphql } from "gatsby"
import React from "react"
import ContactSales from "../../components/common/ContactSales"
import SEORevamp from "../../components/common/SEO_Revamp"
import MainLayout from "../../layouts/MainLayout"
import Banner from "../../components/consulting/Banner"
import Clients from "../../components/web-application/ClientsWeb"
import Projects from "../../components/web-application/Projects"
import ProjectsMobile from "../../components/web-application/ProjectsMobile"
import ServicesOffers from "../../components/consulting/ServicesOffers"
import Consultation from "../../components/consulting/Consultation"
import ConsultingNeed from "../../components/consulting/ConsultingNeed"
import Sets from "../../components/consulting/Sets"
import Ready from "../../components/consulting/Ready"
import ConsultingStacks from "../../components/consulting/ConsultingStacks"
import Smooth from "../../components/consulting/Smooth"
import ConsultingFaqs from "../../components/consulting/ConsultingFaqs"
import ConsultingTechStacksMobile from "../../components/consulting/ConsultingTechStacksMobile"
import ConsultingNeedMobile from "../../components/consulting/ConsultingNeedMobile"
export const Head = ({ data }) => {
  const { metaTitle, metaDescription } = data?.strapiPage?.seo
  return (
    <>
      <Script
        src="https://unpkg.com/@lottiefiles/lottie-player@latest/dist/lottie-player.js"
        strategy="post-hydrate"
      />
      <SEORevamp
        title={metaTitle}
        description={metaDescription}
        image="https://invozone-backend.s3.amazonaws.com/leading_react_native_app_development_company_e5e33e137c.webp"
      />
    </>
  )
}
const webdevelopment = ({ data }) => {
  const [isMobile, setIsMobile] = React.useState(false)
  const [isTablet, setIsTablet] = React.useState(false)

  const handleResize = () => {
    if (window.innerWidth <= 767) {
      setIsMobile(true)
      setIsTablet(false)
    } else if (window.innerWidth <= 1280) {
      setIsTablet(true)
      setIsMobile(false)
    } else {
      setIsMobile(false)
      setIsTablet(false)
    }
  }

  React.useEffect(() => {
    setIsMobile(window.innerWidth <= 767)
    setIsTablet(window.innerWidth <= 1280)
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  const heroSection = data?.strapiPage?.sections[0]
  const servicesOffers = data?.strapiPage?.sections[1]
  const consultation = data?.strapiPage?.sections[2]
  const needConsultation = data?.strapiPage?.sections[3]
  const sets = data?.strapiPage?.sections[4]
  const ready = data?.strapiPage?.sections[5]
  const consultingstacks = data?.strapiPage?.sections[6]
  const work = data?.strapiPage?.sections[7]
  const smooth = data?.strapiPage?.sections[8]
  const clietny = data?.strapiPage?.sections[9]
  const faqs = data?.strapiPage?.sections[10]

  return (
    <MainLayout>
      <Banner strapiData={heroSection} />
      <ServicesOffers strapiData={servicesOffers} />
      <Consultation strapiData={consultation} />
      {!isMobile && !isTablet ? (
        <ConsultingNeed strapiData={needConsultation} />
      ) : (
        <ConsultingNeedMobile strapiData={needConsultation} />
      )}
      <Sets strapiData={sets} />
      <Ready strapiData={ready} />
      {!isMobile && !isTablet ? (
        <ConsultingStacks strapiData={consultingstacks} />
      ) : (
        <ConsultingTechStacksMobile strapiData={consultingstacks} />
      )}
      {!isMobile && !isTablet ? (
        <Projects strapiData={work} />
      ) : (
        <ProjectsMobile strapiData={work} />
      )}
      <Smooth strapiData={smooth} />
      <Clients strapiData={clietny} consulting={true} />
      <ConsultingFaqs strapiData={faqs} />
      <ContactSales bgColor="#f8f8f8" />
    </MainLayout>
  )
}

export const query = graphql`
  query ElixirPage {
    strapiPage(slug: { eq: "consulting-services" }) {
      sections {
        title
        subTitle
        subTitle2
        cards {
          title
          subTitle
          description: childStrapiComponentCardsCardDescriptionTextnode {
            description
          }
          image1 {
            alternativeText
            localFile {
              publicURL
            }
            url
          }
          image2 {
            alternativeText
            localFile {
              publicURL
            }
          }
          buttons {
            title
            url
          }
        }
        secImages {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
            publicURL
          }
          url
        }
        buttons {
          title
          url
        }
        description: childStrapiComponentSectionsSectionDescriptionTextnode {
          id
          description
        }
      }
      buttons {
        url
        title
      }
      seo {
        metaTitle
        metaDescription
      }
    }
    testimonials: strapiPage(slug: { eq: "testimonial" }) {
      sections {
        title
        subTitle
        cards {
          title
          subTitle
          image1 {
            alternativeText
            localFile {
              publicURL
            }
          }
        }
      }
    }
  }
`

export default webdevelopment
