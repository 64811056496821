// extracted by mini-css-extract-plugin
export var btn = "Smooth-module--btn--6a584";
export var cards = "Smooth-module--cards--7378d";
export var description = "Smooth-module--description--ed2f0";
export var hireImg = "Smooth-module--hireImg--4eff4";
export var hireTechnicalSetsConsulting = "Smooth-module--hireTechnicalSetsConsulting--b5a07";
export var howWeMakeConsultingWeb = "Smooth-module--howWeMakeConsultingWeb--7211a";
export var subTitle = "Smooth-module--subTitle--09acd";
export var talent = "Smooth-module--talent--48eb6";
export var teamBtn = "Smooth-module--teamBtn--01ab2";
export var text = "Smooth-module--text--29319";
export var tick = "Smooth-module--tick--f7c39";