// extracted by mini-css-extract-plugin
export var cardBg = "ConsultingStacks-module--cardBg--d66ca";
export var cards = "ConsultingStacks-module--cards--6356a";
export var cardsContent = "ConsultingStacks-module--cardsContent--0a24d";
export var description = "ConsultingStacks-module--description--2b6e8";
export var heading = "ConsultingStacks-module--heading--1247f";
export var iconssCard = "ConsultingStacks-module--iconssCard--f993d";
export var nav = "ConsultingStacks-module--nav--3a501";
export var navItem = "ConsultingStacks-module--nav-item--7883a";
export var navbarBlock = "ConsultingStacks-module--navbarBlock--7bee2";
export var tabData = "ConsultingStacks-module--tabData--d0ea9";
export var tech = "ConsultingStacks-module--tech--4b878";
export var techIcon = "ConsultingStacks-module--techIcon--88232";
export var techImg = "ConsultingStacks-module--techImg--67244";
export var techStacksConsulting = "ConsultingStacks-module--techStacksConsulting--3f435";
export var technologyIcon = "ConsultingStacks-module--technologyIcon--2ca09";