// extracted by mini-css-extract-plugin
export var card = "ServicesOffers-module--card--d6df9";
export var cardWrapper = "ServicesOffers-module--cardWrapper--111e8";
export var description = "ServicesOffers-module--description--79109";
export var descriptionCard = "ServicesOffers-module--descriptionCard--59b7b";
export var heading = "ServicesOffers-module--heading--b917e";
export var loader = "ServicesOffers-module--loader--8f91d";
export var lodaMoreBtn = "ServicesOffers-module--lodaMoreBtn--5f0e8";
export var lodalessBtn = "ServicesOffers-module--lodalessBtn--c1f83";
export var servicesOffersConsulting = "ServicesOffers-module--servicesOffersConsulting--b60ef";
export var showlessdiv = "ServicesOffers-module--showlessdiv--18476";
export var spin = "ServicesOffers-module--spin--efa45";
export var trailBg = "ServicesOffers-module--trailBg--2fae5";
export var unOrderedListCard = "ServicesOffers-module--unOrderedListCard--27fd6";