// extracted by mini-css-extract-plugin
export var CustombannerLeft = "Banner-module--CustombannerLeft--82256";
export var CustombannerRight = "Banner-module--CustombannerRight--8e454";
export var banerImg = "Banner-module--banerImg--453bf";
export var bannerBtn = "Banner-module--bannerBtn--bd526";
export var bannerHeading = "Banner-module--bannerHeading--d2728";
export var bannerWebConsulting = "Banner-module--bannerWebConsulting--f3d88";
export var btn_white2_border = "Banner-module--btn_white2_border--05808";
export var cards = "Banner-module--cards--84b74";
export var head = "Banner-module--head--f580b";
export var heading = "Banner-module--heading--7675f";
export var subTitle = "Banner-module--subTitle--78c0e";
export var subTitle2 = "Banner-module--subTitle2--88384";
export var talent = "Banner-module--talent--23bdf";
export var text = "Banner-module--text--82c15";
export var tick = "Banner-module--tick--ee439";