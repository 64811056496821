import { Link } from "gatsby"
import React from "react"
import * as styles from "./Ready.module.scss"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
const Ready = ({ strapiData }) => {
  return (
    <React.Fragment>
      <Container className={styles.ReadyConsulting}>
        <Row>
          <Col xs={12}>
            <div className={styles.readyContent}>
              <h2
                className={styles.headingWebConsulting}
                dangerouslySetInnerHTML={{ __html: strapiData?.title }}
              />
              <div className={`${styles.needBtnWeb}`}>
                {strapiData?.buttons[0] && (
                  <Link
                    to={strapiData?.buttons[0]?.url}
                    className={styles.btn_white2_border_banner_web_App_need_btn}
                  >
                    {strapiData?.buttons[0]?.title}
                  </Link>
                )}
              </div>
              <div
                className={` justify-content-center d-flex ${styles.bottomExperience}`}
              >
                {strapiData?.cards?.map((e, i) => (
                  <div className={styles.experienceDetails} key={i}>
                    <p
                      className={styles.experienceData}
                      dangerouslySetInnerHTML={{
                        __html: e?.subTitle,
                      }}
                    />
                    <h3 className={styles.experienceTitle}>{e?.title}</h3>
                  </div>
                ))}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  )
}

export default Ready
