// extracted by mini-css-extract-plugin
export var SliderWidth = "ConsultingNeed-module--SliderWidth--04f08";
export var arrowImg = "ConsultingNeed-module--arrowImg--88768";
export var card = "ConsultingNeed-module--card--66adc";
export var cardImg = "ConsultingNeed-module--cardImg--d61b2";
export var cardMian = "ConsultingNeed-module--cardMian--02426";
export var cardWrapper = "ConsultingNeed-module--cardWrapper--60b9d";
export var cards = "ConsultingNeed-module--cards--10a9b";
export var cmsWebIconCOntainer = "ConsultingNeed-module--cmsWebIconCOntainer--445be";
export var cmsWebIconCOntainerLeft = "ConsultingNeed-module--cmsWebIconCOntainerLeft--62e41";
export var consultingNeedsWebDevelopment = "ConsultingNeed-module--consultingNeedsWebDevelopment--cf617";
export var description = "ConsultingNeed-module--description--99e6f";
export var heading = "ConsultingNeed-module--heading--2abc1";
export var headingPremium = "ConsultingNeed-module--headingPremium--9952e";
export var portfolioArrowIcon = "ConsultingNeed-module--portfolioArrowIcon--e9419";
export var portfolioArrowIconCover = "ConsultingNeed-module--portfolioArrowIconCover--09c16";
export var portfolioArrowRightIconCover = "ConsultingNeed-module--portfolioArrowRightIconCover--0919a";
export var premiumImg = "ConsultingNeed-module--premiumImg--a7d91";
export var providingImg = "ConsultingNeed-module--providingImg--d25f4";
export var teamButton = "ConsultingNeed-module--teamButton--fd0a9";
export var trailBg = "ConsultingNeed-module--trailBg--68ac9";